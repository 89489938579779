import React from 'react';
import { Link } from 'gatsby';
import { SEO } from '../components/SEO';
import Layout from '../components/layout';
import logo from '../images/Logo_white@2x.png';

export default props => {
  return (
    <>
      <SEO title="Sivua ei löytynyt" description="Sivua ei löytynyt" />
      <Layout
        additionalClassForWrapper="contact-bg"
        headerBgStyles={{
          display: 'flex',
          flexDirection: 'column',
        }}
        headerNavLinkStyle={{ color: 'white' }}
        mainTitle={'Oops! Sivua ei löytynyt.'}
        mainTitleClasses={'mt-5 mb-5'}
        headerTitleStyle={{ color: 'white' }}
        logo={logo}
        {...props}
      >
        <div className="container">
          <div className="row d-flex justify-content-center pt-0 pb-4">
            <div className="col-lg-12 pt-5 pr-md-6">
              <p>
                Pahoittelut! Emme löytäneet etsimääsi sivua. Alla muutama
                linkki, joka voi liittyä tai olla liittymättä etsimääsi sivuun:
              </p>
              <ul>
                <li>
                  <Link to="/fi/excel-kurssi-netissa">
                    Ilmainen Excel-verkkokurssi
                  </Link>
                </li>
                <li>
                  <a href="http://eepurl.com/cSbTxn" target="_blank">
                    Tilaa uutiskirje
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
